exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-cara/src/templates/cara.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-and-article-index-tsx": () => import("./../../../src/pages/blog-and-article/index.tsx" /* webpackChunkName: "component---src-pages-blog-and-article-index-tsx" */),
  "component---src-pages-blog-and-article-write-mdx": () => import("./../../../src/pages/blog-and-article/write.mdx" /* webpackChunkName: "component---src-pages-blog-and-article-write-mdx" */),
  "component---src-pages-blog-site-jsx": () => import("./../../../src/pages/blog-site.jsx" /* webpackChunkName: "component---src-pages-blog-site-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-glossary-index-tsx": () => import("./../../../src/pages/glossary/index.tsx" /* webpackChunkName: "component---src-pages-glossary-index-tsx" */),
  "component---src-pages-projects-artificial-intelegence-jsx": () => import("./../../../src/pages/projects/artificial-intelegence.jsx" /* webpackChunkName: "component---src-pages-projects-artificial-intelegence-jsx" */),
  "component---src-pages-projects-automated-things-jsx": () => import("./../../../src/pages/projects/automated-things.jsx" /* webpackChunkName: "component---src-pages-projects-automated-things-jsx" */),
  "component---src-pages-projects-mobile-jsx": () => import("./../../../src/pages/projects/mobile.jsx" /* webpackChunkName: "component---src-pages-projects-mobile-jsx" */),
  "component---src-pages-projects-web-jsx": () => import("./../../../src/pages/projects/web.jsx" /* webpackChunkName: "component---src-pages-projects-web-jsx" */),
  "component---src-pages-research-activity-artificial-intelegence-index-jsx": () => import("./../../../src/pages/research-activity/artificial-intelegence/index.jsx" /* webpackChunkName: "component---src-pages-research-activity-artificial-intelegence-index-jsx" */),
  "component---src-pages-research-activity-back-end-index-jsx": () => import("./../../../src/pages/research-activity/back-end/index.jsx" /* webpackChunkName: "component---src-pages-research-activity-back-end-index-jsx" */),
  "component---src-pages-research-activity-index-jsx": () => import("./../../../src/pages/research-activity/index.jsx" /* webpackChunkName: "component---src-pages-research-activity-index-jsx" */),
  "component---src-pages-research-activity-react-things-index-jsx": () => import("./../../../src/pages/research-activity/react-things/index.jsx" /* webpackChunkName: "component---src-pages-research-activity-react-things-index-jsx" */),
  "component---src-pages-research-paper-api-rest-ful-tsx": () => import("./../../../src/pages/research-paper/api-rest-ful.tsx" /* webpackChunkName: "component---src-pages-research-paper-api-rest-ful-tsx" */),
  "component---src-pages-research-paper-ci-cd-tsx": () => import("./../../../src/pages/research-paper/ci-cd.tsx" /* webpackChunkName: "component---src-pages-research-paper-ci-cd-tsx" */),
  "component---src-pages-research-paper-exploration-remix-run-tsx": () => import("./../../../src/pages/research-paper/exploration-remix-run.tsx" /* webpackChunkName: "component---src-pages-research-paper-exploration-remix-run-tsx" */),
  "component---src-pages-research-paper-js-es-6-tsx": () => import("./../../../src/pages/research-paper/js-es6.tsx" /* webpackChunkName: "component---src-pages-research-paper-js-es-6-tsx" */),
  "component---src-pages-research-paper-js-or-ts-tsx": () => import("./../../../src/pages/research-paper/js-or-ts.tsx" /* webpackChunkName: "component---src-pages-research-paper-js-or-ts-tsx" */),
  "component---src-pages-research-paper-react-15-min-tsx": () => import("./../../../src/pages/research-paper/react-15-min.tsx" /* webpackChunkName: "component---src-pages-research-paper-react-15-min-tsx" */),
  "component---src-pages-research-paper-react-native-tsx": () => import("./../../../src/pages/research-paper/react-native.tsx" /* webpackChunkName: "component---src-pages-research-paper-react-native-tsx" */),
  "component---src-pages-research-paper-reactivity-tsx": () => import("./../../../src/pages/research-paper/reactivity.tsx" /* webpackChunkName: "component---src-pages-research-paper-reactivity-tsx" */),
  "component---src-pages-research-paper-redux-tsx": () => import("./../../../src/pages/research-paper/redux.tsx" /* webpackChunkName: "component---src-pages-research-paper-redux-tsx" */),
  "component---src-pages-research-paper-web-fundamental-tsx": () => import("./../../../src/pages/research-paper/web-fundamental.tsx" /* webpackChunkName: "component---src-pages-research-paper-web-fundamental-tsx" */),
  "component---src-pages-research-playground-index-jsx": () => import("./../../../src/pages/research-playground/index.jsx" /* webpackChunkName: "component---src-pages-research-playground-index-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/Works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */),
  "component---src-pages-workshop-index-tsx": () => import("./../../../src/pages/workshop/index.tsx" /* webpackChunkName: "component---src-pages-workshop-index-tsx" */)
}

